import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { useDatabase } from "../../context/DatabaseContext";
import createRichTextOptions from "../../utils/richTextOptions";
import { RandstadLogo } from "../Icons";

export default function Dashboard() {
  const history = useHistory();
  const { formData, formBody } = useDatabase();
  const [bucketsFilled, set_bucketsFilled] = useState(false);
  const [parsedParagraphText, set_parsedParagraphText] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    set_parsedParagraphText(
      formBody?.fields?.heroDescription
        ? createRichTextOptions(formBody.fields.heroDescription)
        : null
    );
  }, [formBody]);

  function handleGoToForm(e) {
    try {
      history.push(`/dashboard/${e}`);
    } catch (e) {
      console.log(e);
    }
  }

  function handleButtonClick(e) {
    try {
      history.push(`/priorities`);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (!formData) return;
    set_bucketsFilled(
      formBody?.fields?.bucket.map((bucket) => {
        const currentBucket = formData.find((item) => {
          return bucket.sys.id === item.id;
        });
        const questionsAnswered = currentBucket?.data
          ? currentBucket.data.length
          : 0;
        return questionsAnswered / bucket.fields.question.length === 1;
      })
    );
  }, [formData]);

  if (!formData || !formBody)
    return (
      <div className="loading-container">
        <h2 className="loading-message">Loading...</h2>
      </div>
    );

  return (
    <section className="dashboard-section">
      <section className="dashboard-intro-section">
        <div className="container hero-container">
          <RandstadLogo className="randstad-logo randstad-logo-dashboard" />
          <h1 className="dashboard-hero-title">
            {formBody ? formBody.fields?.heroTitle : null}
          </h1>
          <h1 className="dashboard-hero-subtitle">
            {formBody ? formBody.fields?.heroSubtitle : null}
          </h1>
          <div className="intro-text hero-description">
            {parsedParagraphText}
          </div>
        </div>
      </section>

      <div className="dashboard-container">
        <ul className="bucket-list">
          {formBody
            ? formBody.fields?.bucket.map((bucket) => {
                const currentBucket = formData.find((item) => {
                  return bucket.sys.id === item.id;
                });
                const questionsAnswered = currentBucket?.data
                  ? currentBucket.data.length
                  : 0;

                return (
                  <section
                    key={bucket.sys.id}
                    className="bucket-list-item"
                    style={{ backgroundColor: bucket.fields.backgroundColor }}
                  >
                    <div className="container">
                      <div className="custom-column column-1">
                        <h3
                          className="bucket-title-dashboard"
                          style={{
                            color:
                              bucket.fields.backgroundColor === "#101941"
                                ? "#FFB511"
                                : "#101941",
                          }}
                        >
                          {bucket.fields.bucketTitle}
                        </h3>
                        <p className="bucket-list-item-text">
                          {bucket.fields.bucketText}
                        </p>
                      </div>
                      <div className="custom-column column-2">
                        <h1
                          className="progress-indicator"
                          style={{
                            color:
                              bucket.fields.backgroundColor === "#FFB512" ||
                              bucket.fields.backgroundColor === "#E74537"
                                ? "#101941"
                                : "#FFB512",
                          }}
                        >
                          {questionsAnswered}/{bucket.fields.question.length}
                        </h1>
                      </div>
                      <div className="custom-column column-3">
                        <button
                          className="custom-button-secondary"
                          onClick={(e) => handleGoToForm(bucket.sys.id)}
                        >
                          {questionsAnswered / bucket.fields.question.length ===
                          1
                            ? "edit"
                            : questionsAnswered /
                                bucket.fields.question.length ===
                              0
                            ? "start"
                            : "continue"}
                        </button>
                      </div>
                    </div>
                  </section>
                );
              })
            : null}
        </ul>
        <button
          className="custom-button submit-button"
          onClick={handleButtonClick}
          disabled={bucketsFilled ? bucketsFilled.includes(false) : true}
        >
          Submit answers
        </button>
      </div>
    </section>
  );
}
