import React, { useState, useEffect } from "react";
import "./Users.scss";
import { useAuth } from "../../context/AuthContext";
import { RandstadLogo } from "../Icons";

export default function Users() {
  const {
    formBody,
    auth0token,
    authorisedUsers,
    appendAuthorisedUsers,
    removeAuthorisedUser,
    currentUser
  } = useAuth();

  const [users, set_users] = useState([]);

  useEffect(() => {
    set_users(authorisedUsers[currentUser.country]);
  }, [authorisedUsers, currentUser.country]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="c-user-list">
      <section className="dashboard-intro-section">
        <div className="container">
          <RandstadLogo className="randstad-logo" />
        </div>
      </section>

      <section className="user-list-container">
        <div className="container">
          <ul className="user-list">
            <h3 className="current-users">current users</h3>
            {users && users.length > 0
              ? users.map((user, i) => (
                  <li key={i}>
                    <span className="user-email"> {user.email}</span>
                    <button
                      value={user.email}
                      onClick={async (e) => {
                        const newUserList = await removeAuthorisedUser(
                          e.target.value,
                          auth0token
                        );
                        set_users(newUserList);
                      }}
                    >
                      X
                    </button>
                  </li>
                ))
              : null}
            <div className="new-user-block">
              <h5 className="add-new-users">Add new users</h5>
              <li>
                <form
                  className="add-user-email-form"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const newUserList = await appendAuthorisedUsers(
                      e.target[0].value,
                      auth0token
                    );
                    set_users(newUserList);
                  }}
                >
                  {/* <label htmlFor="email">Email</label> */}
                  <input id="email" placeholder={"youremail@randstad.com"} />
                  <button className="custom-button" type="submit">
                    Add
                  </button>
                </form>
              </li>
            </div>
          </ul>
        </div>
      </section>
    </section>
  );
}
