import React from "react";
import "./ResultChart.scss";
import { PolarArea } from "react-chartjs-2";
import { useDatabase } from "../../../context/DatabaseContext";

export default function ResultChart({ totalScore, scoreArray }) {
  const { formData } = useDatabase();
  const labels = formData ? formData.map((item) => item.bucketName) : [];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: scoreArray,
        backgroundColor: ["#0F1841", "#E74436", "#2175D8", "#FEB512"],
        borderWidth: 0,
      },
    ],
  };
  var chartOptions = {
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
      tooltip: {
        enabled: true,
        xAlign: "left",
        yAlign: "bottom",
      },
    },
    elements: {
      line: {
        borderColor: "#FEB512",
        borderWidth: "8px",
      },
      bar: {
        borderWidth: "9px",
      },
    },
    scales: {
      r: {
        display: false,
      },
    },
  };

  return (
    <div className="chart-container">
      <div className="result-chart">
        <PolarArea data={data} options={chartOptions} />
        <div className="total-score-circle">
          <p className="total-score-text">{totalScore}</p>
        </div>
      </div>
      <div className="legend-of-chart">
        <span className="legend-item distribution-item">Distribution</span>
        <span className="legend-item decisions-item">Decisions</span>
        <span className="legend-item data-item">Data</span>
        <span className="legend-item organisation-item">Organisation</span>
      </div>
    </div>
  );
}
