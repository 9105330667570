import React, { useState, useEffect } from "react";
import NotesComponent from "./NotesComponent";

export default function QuestionComponent({
  data,
  setAnswer,
  id,
  questionActive,
  questionName,
  backgroundColor,
  questionArray,
}) {
  const currentQuestion = questionArray.filter((question) => {
    return question.questionId === id;
  });

  const [openModal, set_openModal] = useState(false);
  const [editNote, set_editNote] = useState("");
  const [activeAnswer, set_activeAnswer] = useState({
    answer: "",
    maturityLevel: "",
  });

  useEffect(() => {
    if (currentQuestion.length) {
      set_editNote(currentQuestion[0].note);
    }
  }, [currentQuestion]);

  const onAddNote = (answer, maturityLevel, textNote) => {
    setAnswer(id, answer, maturityLevel, questionName, textNote);
  };

  return (
    <div className="form-component-question-list-item">
      {openModal ? (
        <NotesComponent
          onAddNote={onAddNote}
          openModal
          setOpenModal={set_openModal}
          id
          answer={activeAnswer.answer}
          maturityLevel={activeAnswer.maturityLevel}
          questionName={questionName}
          editNote={editNote}
        />
      ) : null}
      <h3 className="question-name">{data ? data.questionTitle : null}</h3>
      <p className="question-description">
        {data ? data.questionSubtitle : null}
      </p>
      <ul className="answer-list">
        {data
          ? data.questionAnswer
              .sort(function (a, b) {
                return a.fields.maturityLevel - b.fields.maturityLevel;
              })
              .map((item) => {
                return (
                  <li
                    className={`answer-list-item ${
                      questionActive === item.fields.answer ? "answered" : ""
                    }`}
                    key={item.sys.id}
                    onClick={() => {
                      if (questionActive !== item.fields.answer) {
                        setAnswer(
                          id,
                          item.fields.answer,
                          item.fields.maturityLevel,
                          questionName,
                          ""
                        );
                      }
                    }}
                    style={{
                      backgroundColor:
                        questionActive === item.fields.answer
                          ? backgroundColor
                          : "transparent",
                    }}
                  >
                    <p className="answer-text">{item.fields.answer}</p>

                    {editNote && editNote !== "" ? (
                      <button
                        className="add-note-button exists"
                        onClick={() => {
                          set_activeAnswer({
                            answer: item.fields.answer,
                            maturityLevel: item.fields.maturityLevel,
                          });
                          set_editNote(editNote);
                          set_openModal(!openModal);
                        }}
                        style={{
                          display:
                            questionActive === item.fields.answer
                              ? "inline"
                              : "none",
                        }}
                      >
                        see note
                      </button>
                    ) : (
                      <button
                        className="add-note-button"
                        onClick={() => {
                          set_activeAnswer({
                            answer: item.fields.answer,
                            maturityLevel: item.fields.maturityLevel,
                          });
                          set_openModal(!openModal);
                        }}
                        style={{
                          display:
                            questionActive === item.fields.answer
                              ? "inline"
                              : "none",
                        }}
                      >
                        Add a note
                      </button>
                    )}
                  </li>
                );
              })
          : null}
      </ul>
    </div>
  );
}
