import React, { useState, useEffect } from "react";
import "./IntroductionBlock.scss";
import { Link } from "react-router-dom";
import { useDatabase } from "../../context/DatabaseContext";
import createRichTextOptions from "../../utils/richTextOptions";
import { IntroductionImage, RandstadLogo } from "../Icons";

export default function IntroductionBlock() {
  const { formIntro } = useDatabase();
  const [content, set_content] = useState();
  const [parsedParagraphText, set_parsedParagraphText] = useState("");

  useEffect(() => {
    if (!formIntro || !formIntro.items) return;
    formIntro.items.forEach(function (entry) {
      set_content(entry);
    });
  }, [formIntro]);

  useEffect(() => {
    set_parsedParagraphText(
      content?.fields.introText
        ? createRichTextOptions(content.fields.introText)
        : null
    );
  }, [content]);

  if (!content || !content.fields)
    return (
      <div className="loading-container">
        <h2 className="loading-message">Loading...</h2>
      </div>
    );

  return (
    <div className="instruction-page-container">
      <section className="intro-section">
        <div className="container hero-container">
          <RandstadLogo className="randstad-logo" />
          <h1 className="hero-title">
            {content ? content.fields.heroTitle : "no content"}
          </h1>
          <h1 className="hero-subtitle">
            {content ? content.fields.heroSubtitle : "no content"}
          </h1>
          <div className="intro-text">{parsedParagraphText}</div>
        </div>
      </section>
      <section className="list-section">
        <div className="container">
          <div className="list-container">
            <ul className="intro-list">
              <h2 className="intro-title">
                {content ? content.fields.sectionTitle : null}
              </h2>
              {content?.fields.multipleColumnParagraph.fields.column.map(
                (item, index) => {
                  const parsedColumnText = item.fields.columnText
                    ? createRichTextOptions(item.fields.columnText)
                    : null;
                  return (
                    <li key={item.sys.id} className="intro-list-item">
                      <h5>{item.fields.columnTitle}</h5>
                      <div>{parsedColumnText}</div>
                    </li>
                  );
                }
              )}
              <Link className="custom-button start-button" to={"/dashboard"}>
                {content ? content.fields.buttonText : null}
              </Link>
            </ul>
            <IntroductionImage />
          </div>
        </div>
      </section>
    </div>
  );
}
