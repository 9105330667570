import React, { useState, useEffect } from "react";
import "./Help.scss";
import { useAuth } from "../../context/AuthContext";
import { useDatabase } from "../../context/DatabaseContext";
import createRichTextOptions from "../../utils/richTextOptions";
import {
  ArrowIcon,
  IntroductionImage,
  ContactIcon,
  MailIcon,
  CloseIcon,
} from "../Icons";

export default function Help({ helpActive, setHelpActive }) {
  const { faqSection, contactSection, formIntro } = useDatabase();
  const [formBody, set_formBody] = useState();
  const [faqBody, set_faqBody] = useState();
  const [contactBody, set_contactBody] = useState();
  const [activeQuestion, set_activeQuestion] = useState();

  useEffect(() => {
    if (!formIntro || !formIntro.items) return;
    formIntro.items.forEach(function (entry) {
      set_formBody(entry);
    });
    if (!faqSection || !faqSection.items) return;
    faqSection.items.forEach(function (entry) {
      set_faqBody(entry);
    });
    if (!contactSection || !contactSection.items) return;
    contactSection.items.forEach(function (entry) {
      set_contactBody(entry);
    });
  }, [formIntro]);

  const parsedContactParagraphText = contactBody?.fields.sectionParagraph
    ? createRichTextOptions(contactBody.fields.sectionParagraph)
    : null;

  const onClickActive = (id) => {
    if (activeQuestion === id) {
      set_activeQuestion();
      return;
    }
    set_activeQuestion(id);
  };

  return (
    <section className="help-section">
      <section className="instruction-page-container">
        <div
          onClick={() => {
            setHelpActive(!helpActive);
          }}
          className="help-button"
        >
          <p>close</p>
          <CloseIcon />
        </div>
        <section className="list-section">
          <div className="list-container">
            <ul className="intro-list">
              <h2 className="intro-title">
                {formBody ? formBody.fields.sectionTitle : null}
              </h2>
              {formBody?.fields.multipleColumnParagraph.fields.column.map(
                (item, index) => {
                  const parsedColumnText = item.fields.columnText
                    ? createRichTextOptions(item.fields.columnText)
                    : null;
                  return (
                    <li key={item.sys.id} className="intro-list-item">
                      <h5>{item.fields.columnTitle}</h5>
                      <div>{parsedColumnText}</div>
                    </li>
                  );
                }
              )}
            </ul>
            <IntroductionImage />
          </div>
        </section>
        <section className="faq-block">
          <h2 className="faq-title">
            {faqBody ? faqBody.fields.sectionTitle : null}
          </h2>
          <ul className="help-question-list">
            {faqBody
              ? faqBody.fields.question.map((item, index) => {
                  return (
                    <li key={item.sys.id + index}>
                      <h5>{item.fields.question}</h5>
                      <ul className="help-answer-list">
                        {item.fields.answer.map((answer) => {
                          const parsedColumnText = answer.fields.description
                            ? createRichTextOptions(answer.fields.description)
                            : null;

                          return (
                            <li
                              className="answer-conatainer"
                              key={answer.sys.id}
                            >
                              <h5
                                onClick={() => onClickActive(answer.sys.id)}
                                className="answer-title"
                              >
                                {answer.fields.answer}
                              </h5>
                              <ArrowIcon
                                className={`help-arrow ${
                                  activeQuestion === answer.sys.id
                                    ? "active-icon"
                                    : null
                                }`}
                              />
                              <div
                                className={`answer-description ${
                                  activeQuestion === answer.sys.id
                                    ? "active"
                                    : null
                                }`}
                              >
                                {parsedColumnText}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })
              : null}
          </ul>
        </section>
        <section className="contact-block">
          <h2 className="contact-title">
            {contactBody ? contactBody.fields.sectionTitle : null}
          </h2>
          <div className="contact-paragraph">{parsedContactParagraphText}</div>
          <a
            className="contact-link"
            href={`mailto:${
              contactBody ? contactBody.fields.emailAdress : null
            }`}
          >
            <MailIcon />
            {contactBody ? contactBody.fields.emailAdress : null}
          </a>
          <ContactIcon />
        </section>
      </section>
    </section>
  );
}
