import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { AuthProvider } from "./context/AuthContext";
import { Auth0Provider } from "@auth0/auth0-react";
import { DatabaseProvider } from "./context/DatabaseContext";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={"globalidp.randstadservices.com"}
      clientId={"MXXSfoOjPkj6yxBF6ZbMB8TtCZbszBGT"}
      redirectUri={window.location.origin}
    >
      <AuthProvider>
        <DatabaseProvider>
          <App />
        </DatabaseProvider>
      </AuthProvider>
    </Auth0Provider>
  </React.StrictMode>,
  rootElement
);
