import React, { useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AuthContext = React.createContext();
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  async function appendAuthorisedUsers(email, auth0token) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("token", auth0token);
      myHeaders.append("Content-Type", "application/json");
      const currentCountryUserList = authorisedUsers[currentUser.country];
      const updatedUserList = [{ email }, ...currentCountryUserList];

      const updatedCountryUserList = {...authorisedUsers, [currentUser.country]: updatedUserList}

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          userList: updatedUserList,
          country: currentUser.country,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_PRIVATEENDPOINT,
        requestOptions
      );
      const { update } = await response.json();
      if (update) {
        set_authorisedUsers(updatedCountryUserList);
      }
    } catch (e) {
      console.log("ERROR", e);
    }
  }

  async function removeAuthorisedUser(email, auth0token) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("token", auth0token);
      myHeaders.append("Content-Type", "application/json");
      const currentCountryUserList = authorisedUsers[currentUser.country];
      const updatedUserList = currentCountryUserList.filter((e) => e.email !== email);
    
      const updatedCountryUserList = {...authorisedUsers, [currentUser.country]: updatedUserList}

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({
          userList: updatedUserList,
          country: currentUser.country,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_PRIVATEENDPOINT,
        requestOptions
      );
      const { update } = await response.json();
      if (update) {
        set_authorisedUsers(updatedCountryUserList);
      }
    } catch (e) {
      console.log("ERROR", e);
    }
  }

  //get users in opco
  async function getAuthorisedUsers(auth0token) {
    try {
      var myHeaders = new Headers();
      myHeaders.append("token", auth0token);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        process.env.REACT_APP_PRIVATEENDPOINT,
        requestOptions
      );
      const { userList } = await response.json();

      if (userList !== 0) {
        set_authorisedUsers(userList);
      }
    } catch (e) {
      console.log("ERROR", e);
    }
  }
  const {
    isLoading,
    error,
    user,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const [currentUser, setCurrentUser] = useState();
  function loginWithGoogle() {
    return loginWithRedirect;
  }

  const [auth0token, set_auth0token] = useState("");
  const [authorisedUsers, set_authorisedUsers] = useState([]);

  useEffect(() => {
    if (!user) return;
    const setCountry = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          scope: "openid",
        });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var payload = JSON.stringify({
          userEmail: user.email,
          key: "M8LR:xFCb?b,{28Bx$sm",
          token: accessToken,
        });
        const options = {
          method: "POST",
          body: payload,
          headers: myHeaders,
        };

        const checkCountry = await fetch(
          "https://us-central1-rsg-maturity-prd-62e5.cloudfunctions.net/userAuth",
          options
        );

        const fullUserResponse = await checkCountry.json();
        const { countryList } = fullUserResponse;

        if (!countryList) {
          setCurrentUser({ ...user, unauthorized: true });
          return;
        }
        let country;
        if (countryList.length === 1) {
          country = countryList[0];
        }
        setCurrentUser({ ...user, countryList: countryList, country: country });
        set_auth0token(accessToken);
        await getAuthorisedUsers(accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };
    setCountry();
  }, [user, getAccessTokenSilently]);

  const updateCurrentUser = (currentUser, country) => {
    setCurrentUser({ ...currentUser, country: country });
  };

  function logoutApp() {
    return logout();
  }

  const value = {
    currentUser,
    loginWithGoogle,
    logoutApp,
    auth0token,
    appendAuthorisedUsers,
    removeAuthorisedUser,
    authorisedUsers,
    updateCurrentUser,
    // getAuthorisedUsers,
  };
  if (isLoading) {
    return (
      <div className="loading-container">
        <h2 className="loading-message">Loading...</h2>
      </div>
    );
  }
  if (error) {
    return (
      <div className="loading-container">
        <h2 className="loading-message">Oops... {error.message}</h2>
      </div>
    );
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
