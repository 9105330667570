import React, { useState, useEffect } from "react";
import { useDatabase } from "../../../context/DatabaseContext";
import "../SuccesPage.scss";

export default function MaturityLevelSection({ bucketName }) {
  const { formData } = useDatabase();
  const [activeMenuItem, set_activeMenuItem] = useState();

  useEffect(() => {
    if (!formData) return;
    set_activeMenuItem(formData[0]?.bucketName);
  }, [formData]);

  const maturityLevelArrays = formData
    .map((item) => {
      const questionFoundational = item.data
        .filter((item) => item.maturityLevel === 1)
        .map((item) => item.questionName);
      const questionConnecting = item.data
        .filter((item) => item.maturityLevel === 2)
        .map((item) => item.questionName);
      const questionIntegrated = item.data
        .filter((item) => item.maturityLevel === 3)
        .map((item) => item.questionName);

      return {
        bucketName: item.bucketName,
        foundational: questionFoundational,
        connecting: questionConnecting,
        integrated: questionIntegrated,
      };
    })
    .find((item) => item.bucketName === bucketName);

  return (
    <section className="maturity-level-section">
      <div className="container maturity-level-block">
        <div className="single-block">
          <h5 className="maturity-level-title foundational-title">
            Foundational
          </h5>
          <div className="question-collection">
            {maturityLevelArrays?.foundational.map((item, index) => {
              return (
                <p key={index} className="question-name foundational">
                  {item}
                </p>
              );
            })}
          </div>
        </div>
        <div className="single-block">
          <h5 className="maturity-level-title connecting-title">Connecting</h5>
          <div className="question-collection">
            {maturityLevelArrays?.connecting.map((item, index) => {
              return (
                <p key={index} className="question-name connecting">
                  {item}
                </p>
              );
            })}
          </div>
        </div>
        <div className="single-block">
          <h5 className="maturity-level-title integrated-title">Integrated</h5>
          <div className="question-collection">
            {maturityLevelArrays?.integrated.map((item, index) => {
              return (
                <p key={index} className="question-name integrated">
                  {item}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
