import React, { useState } from "react";
import { CloseIcon } from "../Icons";
import "./NotesComponent.scss";

export default function NotesComponent({
  openModal,
  setOpenModal,
  onAddNote,
  answer,
  maturityLevel,
  editNote,
}) {
  const [noteInput, set_noteInput] = useState(editNote);

  return (
    <section className="modal-container">
      <div className="add-note-modal">
        <div className="note-block-title">
          <h5 className="note-title">add a note</h5>
          <div
            onClick={() => {
              setOpenModal(!openModal);
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <textarea
          className="note-input-field"
          type="text"
          placeholder="Type here"
          rows="5"
          value={noteInput}
          onChange={(e) => set_noteInput(e.target.value)}
        />
        <button
          onClick={() => {
            onAddNote(answer, maturityLevel, noteInput);
            setOpenModal(!openModal);
          }}
          className="save-button"
        >
          save note
        </button>
      </div>
    </section>
  );
}
