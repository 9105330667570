import React, { useState, useEffect } from "react";
import { useDatabase } from "../../../context/DatabaseContext";
import createRichTextOptions from "../../../utils/richTextOptions";
import "../SuccesPage.scss";
import ResultChart from "./ResultChart";

function scoreCalculator(data, scoreType) {
  const scoreMapper = [
    {
        "input": "1.00",
        "output": "1.0",
        "maturity": "Foundational"
    },
    {
        "input": "1.05",
        "output": "1.1",
        "maturity": "Foundational"
    },
    {
        "input": "1.10",
        "output": "1.2",
        "maturity": "Foundational"
    },
    {
        "input": "1.15",
        "output": "1.3",
        "maturity": "Foundational"
    },
    {
        "input": "1.20",
        "output": "1.4",
        "maturity": "Foundational"
    },
    {
        "input": "1.25",
        "output": "1.5",
        "maturity": "Foundational"
    },
    {
        "input": "1.30",
        "output": "1.6",
        "maturity": "Foundational"
    },
    {
        "input": "1.35",
        "output": "1.7",
        "maturity": "Foundational"
    },
    {
        "input": "1.40",
        "output": "1.8",
        "maturity": "Foundational"
    },
    {
        "input": "1.45",
        "output": "1.9",
        "maturity": "Foundational"
    },
    {
        "input": "1.50",
        "output": "2.0",
        "maturity": "Foundational"
    },
    {
        "input": "1.60",
        "output": "2.1",
        "maturity": "Connected"
    },
    {
        "input": "1.70",
        "output": "2.2",
        "maturity": "Connected"
    },
    {
        "input": "1.80",
        "output": "2.3",
        "maturity": "Connected"
    },
    {
        "input": "1.90",
        "output": "2.4",
        "maturity": "Connected"
    },
    {
        "input": "2.00",
        "output": "2.5",
        "maturity": "Connected"
    },
    {
        "input": "2.10",
        "output": "2.6",
        "maturity": "Connected"
    },
    {
        "input": "2.20",
        "output": "2.7",
        "maturity": "Connected"
    },
    {
        "input": "2.30",
        "output": "2.8",
        "maturity": "Connected"
    },
    {
        "input": "2.40",
        "output": "2.9",
        "maturity": "Connected"
    },
    {
        "input": "2.50",
        "output": "3.0",
        "maturity": "Integrated"
    },
    {
        "input": "2.55",
        "output": "3.1",
        "maturity": "Integrated"
    },
    {
        "input": "2.60",
        "output": "3.2",
        "maturity": "Integrated"
    },
    {
        "input": "2.65",
        "output": "3.3",
        "maturity": "Integrated"
    },
    {
        "input": "2.70",
        "output": "3.4",
        "maturity": "Integrated"
    },
    {
        "input": "2.75",
        "output": "3.5",
        "maturity": "Integrated"
    },
    {
        "input": "2.80",
        "output": "3.6",
        "maturity": "Integrated"
    },
    {
        "input": "2.85",
        "output": "3.7",
        "maturity": "Integrated"
    },
    {
        "input": "2.90",
        "output": "3.8",
        "maturity": "Integrated"
    },
    {
        "input": "2.95",
        "output": "3.9",
        "maturity": "Integrated"
    },
    {
        "input": "3.00",
        "output": "4.0",
        "maturity": "Integrated"
    }
  ]
  if (scoreType === "bucketScore") {
    const bucketScore =
      Math.round(
        (10 *
          (data.foundational * 1 + data.connecting * 2 + data.integrated * 3)) /
          data.questions
      ) / 10;
    const nextScoreIndex = scoreMapper.findIndex((score) => {
      return bucketScore <= parseFloat(score.input)
    });
    const remappedScore = scoreMapper[nextScoreIndex];
    return parseFloat(remappedScore.output);
  } else if (scoreType === "totalScore") {
    const score = data.map(
      (item) =>
        item.foundational * 1 + item.connecting * 2 + item.integrated * 3
    );
    const questions = data.map((item) => item.questions);

    const sumScore = score.reduce(add, 0); // with initial value to avoid when the array is empty

    const sumQuestions = questions.reduce(add, 0);

    function add(accumulator, a) {
      return accumulator + a;
    }

    const totalScore = Math.round((sumScore / sumQuestions) * 10) / 10;

    const nextScoreIndex = scoreMapper.findIndex((score) => {
      return totalScore <= parseFloat(score.input)
    });
    const remappedScore = scoreMapper[nextScoreIndex];
    return parseFloat(remappedScore.output);
  }
}

export default function ResultSection({ setScore }) {
  const { formData } = useDatabase();
  const [bucketScore, set_bucketScore] = useState();

  useEffect(() => {
    const bucketScore = formData
      ? formData.map((item) => {
          const foundational = item.data.filter(
            (subItem) => subItem.maturityLevel === 1
          );
          const connecting = item.data.filter(
            (subItem) => subItem.maturityLevel === 2
          );
          const integrated = item.data.filter(
            (subItem) => subItem.maturityLevel === 3
          );

          return {
            bucketName: item.bucketName,
            questions: item.data.length,
            foundational: foundational.length,
            connecting: connecting.length,
            integrated: integrated.length,
          };
        })
      : [];

    set_bucketScore(bucketScore);

    const scoreArrayObjects = bucketScore.map((item) => {
      let score;
      if (scoreCalculator(item, "bucketScore") < 2.1) {
        score = "foundational";
      } else if (
        scoreCalculator(item, "bucketScore") >= 2.1 &&
        scoreCalculator(item, "bucketScore") < 3
      ) {
        score = "connecting";
      } else {
        score = "integrated";
      }

      return {
        bucketName: item.bucketName,
        score: score,
      };
    });

    setScore(scoreArrayObjects);
  }, [formData]);

  if (!bucketScore) return <h5>waiting</h5>;

  const scoreArray = bucketScore.map((item) =>
    scoreCalculator(item, "bucketScore")
  );

  const totalScore = scoreCalculator(bucketScore, "totalScore");

  let maturityLevel;

  if (totalScore < 2.1) {
    maturityLevel = "foundational";
  } else if (totalScore >= 2.1 && totalScore < 3) {
    maturityLevel = "connecting";
  } else {
    maturityLevel = "integrated";
  }

  return (
    <section className="result-section">
      <div className="container result-section-div">
        <ResultParagraph maturityLevel={maturityLevel} />
        <ResultChart totalScore={totalScore} scoreArray={scoreArray} />
      </div>
    </section>
  );
}

function ResultParagraph({ maturityLevel }) {
  const { resultSection, formData } = useDatabase();
  const [content, set_content] = useState();
  const [parsedParagraphText, set_parsedParagraphText] = useState("");

  useEffect(() => {
    if (!resultSection || !resultSection.items) return;
    resultSection.items.forEach(function (entry) {
      set_content(entry);
    });
  }, [resultSection]);

  useEffect(() => {
    const paragraphFoundational = content?.fields?.sectionParagraphFoundational
      ? createRichTextOptions(content.fields.sectionParagraphFoundational)
      : null;
    const paragraphConnecting = content?.fields?.sectionParagraphConnecting
      ? createRichTextOptions(content.fields.sectionParagraphConnecting)
      : null;
    const paragraphIntegrated = content?.fields?.sectionParagraphIntegrated
      ? createRichTextOptions(content.fields.sectionParagraphIntegrated)
      : null;

    set_parsedParagraphText({
      foundational: paragraphFoundational,
      connecting: paragraphConnecting,
      integrated: paragraphIntegrated,
    });
  }, [content]);

  if (!content)
    return (
      <div className="loading-container">
        <h2 className="loading-message">Loading...</h2>
      </div>
    );
  if (maturityLevel === "foundational") {
    return (
      <div className="container">
        <h2 className="succes-title">
          {content.fields.sectionTitleFoundational}
        </h2>
        <div className="succes-paragraph">
          {parsedParagraphText.foundational}
        </div>
      </div>
    );
  }
  if (maturityLevel === "connecting") {
    return (
      <div className="container">
        <h2 className="succes-title">
          {content.fields.sectionTitleConnecting}
        </h2>
        <div className="succes-paragraph">{parsedParagraphText.connecting}</div>
      </div>
    );
  }
  if (maturityLevel === "integrated") {
    return (
      <div className="container">
        <h2 className="succes-title">
          {content.fields.sectionTitleIntegrated}
        </h2>
        <div className="succes-paragraph">{parsedParagraphText.integrated}</div>
      </div>
    );
  }
}
