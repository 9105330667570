import React from "react";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import "./Navigation.scss";
import { NavLink } from "react-router-dom";

export default function Navigation() {
  const { currentUser, logoutApp } = useAuth();
  const history = useHistory();

  const handleLogout = async () => {
    try {
      await logoutApp();
      history.push("/login");
    } catch (e) {
      console.log(e);
    }
  };

  if (currentUser)
    return (
      <div className="c-navigation-bottom">
        <ul>
          <li>
            <button
              variant="link"
              className="logout-link"
              onClick={handleLogout}
            >
              <span>Log out</span>
            </button>
          </li>
          <li>
            <NavLink to="/users" activeClassName="hidden">
              Manage users
            </NavLink>
            <NavLink to="/intro" activeClassName="hidden">
              Go to intro
            </NavLink>
            {currentUser.countryList.length > 1 ? (
              <NavLink to="/countries" activeClassName="hidden">
                Change country
              </NavLink>
            ) : null}
            <NavLink to="/dashboard" activeClassName="hidden">
              Questionnaire
            </NavLink>
          </li>
        </ul>
      </div>
    );
  return <div></div>;
}
