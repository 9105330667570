import React, { useEffect, useState } from "react";
import "./Priorities.scss";
import { useDatabase } from "../../context/DatabaseContext";
import createRichTextOptions from "../../utils/richTextOptions";
import { ArrowBackIcon } from "../Icons";
import { Link, useHistory } from "react-router-dom";
import { RandstadLogo } from "../Icons";

export default function Priorities() {
  const history = useHistory();
  const { formPriority, formData, updatePriorityState } = useDatabase();
  const [content, set_content] = useState();
  const [priorityState, set_priorityState] = useState([
    { maturityLevel: "foundational", data: [], amount: 0 },
    { maturityLevel: "connecting", data: [], amount: 0 },
  ]);
  const [buttonEnabled, set_buttonEnabled] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   const connectingAmount = priorityState.find(
  //     (item) => item.maturityLevel === "connecting"
  //   ).amount;
  //   const foundationalAmount = priorityState.find(
  //     (item) => item.maturityLevel === "foundational"
  //   ).amount;
  //   if(arrayOfLevelTwo && arrayOfLevelTwo.length > 0 && connectingAmount === 0) {
  //     set_buttonEnabled(false);
  //   }
  //   else if(arrayOfLevelOne && arrayOfLevelOne.length > 0 && foundationalAmount === 0) {
  //     set_buttonEnabled(false);
  //   }
  //   else {
  //     set_buttonEnabled(true);
  //   }
  // }, [priorityState]);

  useEffect(() => {
    if (!formPriority || !formPriority.items) return;
    formPriority.items.forEach(function (entry) {
      set_content(entry);
    });
  }, [formPriority]);

  useEffect(() => {
    const priorityStateData = formData
      .map((bucket) => {
        const prioQuestion = bucket.data.filter((question) => {
          return question.priorityState;
        });
        return prioQuestion;
      })
      .flat();

    const foundationalPrio = priorityStateData
      .filter((question) => question.maturityLevel === 1)
      .map((item) => item.questionName);
    const connectingPrio = priorityStateData
      .filter((question) => question.maturityLevel === 2)
      .map((item) => item.questionName);

    const foundationalPrioSelected = priorityStateData
      .filter((question) => question.maturityLevel === 1 && priorityState)
      .map((item) => item.questionName).length;

    const connectingPrioSelected = priorityStateData
      .filter((question) => question.maturityLevel === 2 && priorityState)
      .map((item) => item.questionName).length;

    set_priorityState([
      {
        maturityLevel: "foundational",
        data: foundationalPrio,
        amount: foundationalPrioSelected,
      },
      {
        maturityLevel: "connecting",
        data: connectingPrio,
        amount: connectingPrioSelected,
      },
    ]);
  }, [formData]);

  const arrayOfLevelTwo = formData
    .map((item) => {
      const doesConsist = item.data.filter((answer) => {
        if (answer.maturityLevel === 2) return true;
        else return false;
      });
      return doesConsist;
    })
    .flat()
    .map((x) => {
      return { name: x.questionName, id: x.questionId };
    });

  const arrayOfLevelOne = formData
    .map((item) => {
      const doesConsist = item.data.filter((answer) => {
        if (answer.maturityLevel === 1) return true;
        else return false;
      });
      return doesConsist;
    })
    .flat()
    .map((x) => {
      return { name: x.questionName, id: x.questionId };
    });

  const parsedParagraphText = content?.fields.paragraphBlock
    ? createRichTextOptions(content.fields.paragraphBlock)
    : null;

  const setPriorityStatusArray = (maturityLevel, subject) => {
    if (
      priorityState.find(
        (item) =>
          item.maturityLevel === maturityLevel &&
          item.amount > 4 &&
          !item.data.includes(subject.name)
      )
    )
      return;

    const updatedFormData = formData.map((bucket) => {
      const questionArray = bucket.data.map((question) => {
        if (question.questionId === subject.id) {
          if (question.priorityState === true) {
            return { ...question, priorityState: false };
          } else {
            return { ...question, priorityState: true };
          }
        }
        return question;
      });
      return {
        data: questionArray,
        id: bucket.id,
        bucketName: bucket.bucketName,
      };
    });
    updatePriorityState(updatedFormData);
  };

  const onClickSubmit = () => {
    history.push("/result");
  };

  return (
    <section className="priorities-section">
      <div className="priorities-page">
        <Link to="/dashboard" type="link" className="priorities-go-back">
          <ArrowBackIcon color="#101941" />
          back to dashboard
        </Link>
        <RandstadLogo className="randstad-logo prio-logo" />
        <section className="container">
          <div className="Priorities-container">
            <section className="intro-block">
              <h1 className="intro-title">
                {content ? content?.fields.pageTitle : null}
              </h1>
              <div className="intro-text">{parsedParagraphText}</div>
            </section>
            <div>
              {arrayOfLevelOne.length > 0 ?
                <section className="foundational-section">
                  <h3 className="maturity-title foundational-title">
                    foundational
                  </h3>
                  <div className="maturity-item-container ">
                    {arrayOfLevelOne.map((item, index) => {
                      const foundationalArray = priorityState.find(
                        (item) => item.maturityLevel === "foundational"
                      ).data;
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            setPriorityStatusArray("foundational", item)
                          }
                          className="maturity-item foundational-items"
                          style={{
                            backgroundColor: foundationalArray.includes(item.name)
                              ? "#6ca4b4"
                              : null,
                            color: foundationalArray.includes(item.name)
                              ? "white"
                              : null,
                          }}
                        >
                          <p className="item-name">{item.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </section>
              : null}
              {arrayOfLevelTwo.length > 0 ?
                <section className="connecting-section">
                  <h3 className="maturity-title connecting-title">connecting</h3>
                  <div className="maturity-item-container">
                    {arrayOfLevelTwo.map((item, index) => {
                      const foundationalArray = priorityState.find(
                        (item) => item.maturityLevel === "connecting"
                      ).data;

                      return (
                        <div
                          key={index}
                          onClick={() =>
                            setPriorityStatusArray("connecting", item)
                          }
                          className="maturity-item connecting-items"
                          style={{
                            backgroundColor: foundationalArray?.includes(
                              item.name
                            )
                              ? "#e74537"
                              : null,
                            color: foundationalArray?.includes(item.name)
                              ? "white"
                              : null,
                          }}
                        >
                          <p className="item-name">{item.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </section>
              : null}
            </div>
          </div>
        </section>
      </div>
      <button
        onClick={onClickSubmit}
        className="custom-button submit-priority-button"
        disabled={!buttonEnabled}
      >
        Submit answers
      </button>
    </section>
  );
}
