import React, { useState, useEffect } from "react";
import { useDatabase } from "../../../context/DatabaseContext";
import createRichTextOptions from "../../../utils/richTextOptions";
import "../SuccesPage.scss";

export default function ScoreBreakdownSection({ setMenuItem, totalScore }) {
  const { formData } = useDatabase();
  const [activeMenuItem, set_activeMenuItem] = useState();
  const { scoreBreakdownSection } = useDatabase();
  const [content, set_content] = useState();
  const [bucketScore, set_bucketScore] = useState("initial");

  useEffect(() => {
    if (!scoreBreakdownSection || !scoreBreakdownSection.items) return;
    scoreBreakdownSection.items.forEach(function (entry) {
      set_content(entry);
    });
  }, [scoreBreakdownSection]);

  useEffect(() => {
    if (!formData) return;
    set_activeMenuItem(formData[0]?.bucketName);
  }, [formData]);

  useEffect(() => {
    if (!totalScore && !activeMenuItem) return;

    const score = totalScore.find((item) => item.bucketName === activeMenuItem);

    if (score) {
      set_bucketScore(score.score);
    }
  }, [totalScore, activeMenuItem]);

  return (
    <section className="score-breakdown-section">
      <div className="container">
        <h2 className="score-breakdown-title">
          {content?.fields?.sectionTitle}
        </h2>
        <ul className="bucket-menu">
          {formData
            ? formData.map((item, index) => {
                return (
                  <li
                    onClick={() => {
                      set_activeMenuItem(item.bucketName);
                      setMenuItem(item.bucketName);
                    }}
                    key={index}
                    className={`bucket-item ${
                      item.bucketName === activeMenuItem ? "active" : ""
                    }`}
                  >
                    <p className="menu-item-score-breakdown">
                      {item.bucketName}
                    </p>
                  </li>
                );
              })
            : null}
        </ul>
        <div>
          <ScoreBreakdownParagraph
            maturityLevel={bucketScore}
            bucketName={activeMenuItem}
          />
        </div>
      </div>
    </section>
  );
}

function ScoreBreakdownParagraph({ maturityLevel, bucketName }) {
  const { scoreBreakdownSection } = useDatabase();
  const [content, set_content] = useState();
  const [parsedParagraphText, set_parsedParagraphText] = useState("");

  useEffect(() => {
    if (!scoreBreakdownSection || !scoreBreakdownSection.items) return;
    scoreBreakdownSection.items.forEach(function (entry) {
      set_content(entry);
    });
  }, [scoreBreakdownSection]);

  useEffect(() => {
    const distributionFoundational = content?.fields?.distributionFoundational
      ? createRichTextOptions(content.fields.distributionFoundational)
      : null;
    const distributionConnecting = content?.fields?.distributionConnecting
      ? createRichTextOptions(content.fields.distributionConnecting)
      : null;
    const distributionIntegrated = content?.fields?.distributionIntegrated
      ? createRichTextOptions(content.fields.distributionIntegrated)
      : null;
    const decisionsFoundational = content?.fields?.decisionsFoundational
      ? createRichTextOptions(content.fields.decisionsFoundational)
      : null;
    const decisionsConnecting = content?.fields?.decisionsConnecting
      ? createRichTextOptions(content.fields.decisionsConnecting)
      : null;
    const decisionsIntegrated = content?.fields?.decisionsIntegrated
      ? createRichTextOptions(content.fields.decisionsIntegrated)
      : null;
    const dataFoundational = content?.fields?.dataFoundational
      ? createRichTextOptions(content.fields.dataFoundational)
      : null;
    const dataConnecting = content?.fields?.dataConnected
      ? createRichTextOptions(content.fields.dataConnected)
      : null;
    const dataIntegrated = content?.fields?.dataIntegrated
      ? createRichTextOptions(content.fields.dataIntegrated)
      : null;
    const organisationFoundational = content?.fields?.organisationFoundational
      ? createRichTextOptions(content.fields.organisationFoundational)
      : null;
    const organisationConnecting = content?.fields?.organisationConnecting
      ? createRichTextOptions(content.fields.organisationConnecting)
      : null;
    const organisationIntegrated = content?.fields?.organisationIntegrated
      ? createRichTextOptions(content.fields.organisationIntegrated)
      : null;

    set_parsedParagraphText({
      distributionFoundational: distributionFoundational,
      distributionConnecting: distributionConnecting,
      distributionIntegrated: distributionIntegrated,
      decisionsFoundational: decisionsFoundational,
      decisionsConnecting: decisionsConnecting,
      decisionsIntegrated: decisionsIntegrated,
      dataFoundational: dataFoundational,
      dataConnecting: dataConnecting,
      dataIntegrated: dataIntegrated,
      organisationFoundational: organisationFoundational,
      organisationConnecting: organisationConnecting,
      organisationIntegrated: organisationIntegrated,
    });
  }, [content]);

  if (!content)
    return (
      <div className="loading-container">
        <h2 className="loading-message">Loading...</h2>
      </div>
    );

  switch (true) {
    case maturityLevel == "foundational" && bucketName == "Distribution":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.distributionFoundational}
        </div>
      );
    case maturityLevel === "connecting" && bucketName === "Distribution":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.distributionConnecting}
        </div>
      );
    case maturityLevel === "integrated" && bucketName === "Distribution":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.distributionIntegrated}
        </div>
      );

    case maturityLevel === "foundational" && bucketName === "Data":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.dataFoundational}
        </div>
      );
    case maturityLevel === "connecting" && bucketName === "Data":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.dataConnecting}
        </div>
      );
    case maturityLevel === "integrated" && bucketName === "Data":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.dataIntegrated}
        </div>
      );

    case maturityLevel === "foundational" && bucketName === "Decisions":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.decisionsFoundational}
        </div>
      );
    case maturityLevel === "connecting" && bucketName === "Decisions":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.decisionsConnecting}
        </div>
      );
    case maturityLevel === "integrated" && bucketName === "Decisions":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.decisionsIntegrated}
        </div>
      );

    case maturityLevel === "foundational" && bucketName === "Organisation":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.organisationFoundational}
        </div>
      );
    case maturityLevel === "connecting" && bucketName === "Organisation":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.organisationConnecting}
        </div>
      );
    case maturityLevel === "integrated" && bucketName === "Organisation":
      return (
        <div className="score-breakdown-paragraph">
          {parsedParagraphText.organisationIntegrated}
        </div>
      );

    default:
      return <p>Paragraph missing</p>;
  }
}
