import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import * as dataFn from "./utils/database-functions";

const DatabaseContext = React.createContext();

export function useDatabase() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
  const { currentUser } = useAuth();

  const [formData, set_formData] = useState([]);

  const [formBody, set_formBody] = useState();
  const [formIntro, set_formIntro] = useState();
  const [formPriority, set_formPriority] = useState();
  const [contactSection, set_contactSection] = useState();
  const [faqSection, set_faqSection] = useState();
  const [resultSection, set_resultSection] = useState();
  const [scoreBreakdownSection, set_scoreBreakdownSection] = useState();
  const [conclusionSection, set_conclusionSection] = useState();

  const updateFormData = async (id, newQuestionData, questionId) => {
    try {;
      /* The updated answer in the bucket */
      const updatedAnswer = newQuestionData.filter((question) => {
        return question.questionId === questionId
      });
      const currentStoredFormData = await dataFn.getFormDataFromDB(currentUser.country);
      const currentStoredForm = currentStoredFormData.val();

      const updatedFormData = currentStoredForm.map((questionData) => {
        if (id === questionData.id) {
          /* Check for existing data in bucket */
          if(questionData.data) {
            const questionExisted = questionData.data.filter((question) => {
              return question.questionId === questionId;
            });
            if(questionExisted.length > 0) {
              /* Answer existed */
              const updatedQuestionGroup = questionData.data.map((question) => {
                if(question.questionId === questionId) {
                  /* Replace the existing answer */
                  return updatedAnswer[0];
                }
                else {
                  return question;
                }
              })
              return { ...questionData, data: updatedQuestionGroup };
            }
            else {
              /* New answer */
              let updatedQuestionGroup = questionData.data;
              questionData.data.push(updatedAnswer[0]);
              return { ...questionData, data: updatedQuestionGroup };
            }
            
          }
          else {
            /* Empty bucket, add array */
            return { ...questionData, data: updatedAnswer };
          }
        }
        return questionData;
      });
      /*console.log('updatedFormData', updatedFormData);*/
      set_formData(updatedFormData);
      await dataFn.pushFormDataToDB(updatedFormData, currentUser.country);
    } catch (e) {
      console.log(e);
    }
  };

  const updatePriorityState = async (updatedFormData) => {
    try {
      set_formData(updatedFormData);
      await dataFn.pushFormDataToDB(updatedFormData, currentUser.country);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (currentUser.unauthorized) return;
      const bucketContainer = await dataFn.getBucketContainer(
        currentUser.country
      );
      const userResponseData = await dataFn.userResponseData(
        currentUser.country,
        bucketContainer
      );
      const formIntro = await dataFn.getFormIntro();
      const formPriority = await dataFn.getFormPriorities();
      const contactSection = await dataFn.getContactSection();
      const faqSection = await dataFn.getFaqSection();
      const resultSection = await dataFn.getResultSection();
      const scoreBreakdownSection = await dataFn.getScoreBreakdownSection();
      const conclusionSection = await dataFn.getConclusionSection();

      set_formBody(bucketContainer);
      set_formData(userResponseData);
      set_formIntro(formIntro);
      set_formPriority(formPriority);
      set_contactSection(contactSection);
      set_faqSection(faqSection);
      set_resultSection(resultSection);
      set_scoreBreakdownSection(scoreBreakdownSection);
      set_conclusionSection(conclusionSection);
    }
    if (currentUser && currentUser.country) {
      fetchData();
    }
  }, [currentUser]);

  const value = {
    formPriority,
    formBody,
    formData,
    formIntro,
    faqSection,
    contactSection,
    resultSection,
    scoreBreakdownSection,
    conclusionSection,
    submitForm: dataFn.submitFormObject,
    returnBucketById: dataFn.returnBucketById,
    updateFormData,
    updatePriorityState,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
