import React, { useState, useEffect } from "react";
import "./SuccesPage.scss";
import HeroSection from "./components/HeroSection";
import ConclusionSection from "./components/ConclusionSection";
import ResultSection from "./components/ResultSection";
import ScoreBreakdownSection from "./components/ScoreBreakdownSection";
import MaturityLevelSection from "./components/MaturityLevelSection";
import { useDatabase } from "../../context/DatabaseContext";
import { useAuth } from "../../context/AuthContext";

export default function SuccesPage() {
  const { formData, submitForm } = useDatabase();
  const [activeMenuItem, set_activeMenuItem] = useState();
  const [totalScore, set_totalScore] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) return;
    submitForm(currentUser.country)
  }, [currentUser])

  useEffect(() => {
    if (!formData) return;
    set_activeMenuItem(formData[0]?.bucketName);
  }, [formData]);

  if (!totalScore || !formData || !activeMenuItem) {
    return (
      <div className="loading-container ">
        <h2 className="loading-message">Loading...</h2>
      </div>
    );
  }

  return (
    <div className="dashboard-page">
      <HeroSection />
      <ResultSection setScore={set_totalScore} />
      <ScoreBreakdownSection
        totalScore={totalScore}
        setMenuItem={set_activeMenuItem}
      />
      <MaturityLevelSection bucketName={activeMenuItem} />
      <ConclusionSection />
    </div>
  );
}
