import React from "react";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { RandstadLogo, ArrowIcon, ArrowNext } from "../Icons";
import "./SelectCountryScreen.scss";

export default function SelectCountryScreen() {
  const { currentUser, updateCurrentUser } = useAuth();
  const history = useHistory();

  return (
    <section className="c-country-list">
      <section className="dashboard-intro-section">
        <div className="container">
          <RandstadLogo className="randstad-logo" />
        </div>
      </section>

      <section className="country-list-container">
        <h3 className="current-country">Choose your country</h3>
        <ul className="country-list">
          {currentUser && currentUser.countryList && currentUser.countryList.length > 0
            ? currentUser.countryList.map((country, i) => (
                <li key={i} onClick={(e) => {
                  updateCurrentUser(currentUser, country);
                  history.push(`/intro`);
                }}>
                  <span className={`country-name ${country === currentUser.country? 'country-selected' : ''}`}> {country}</span>
                  <button
                    value={country}
                  >
                    <ArrowNext className="arrow-next" />
                  </button>
                </li>
              ))
            : null}
          <div className="transparent-element"></div>
        </ul>
        <ArrowIcon className="arrow-icon-country" />
      </section>
    </section>
  );
}
