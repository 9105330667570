import React, { useState, useEffect } from "react";
import { useDatabase } from "../../../context/DatabaseContext";
import createRichTextOptions from "../../../utils/richTextOptions";
import "../SuccesPage.scss";
import { FlowerIcon } from "../../Icons";

export default function ConclusionSection() {
  const { conclusionSection } = useDatabase();
  const [content, set_content] = useState();
  const [parsedParagraphText, set_parsedParagraphText] = useState("");

  useEffect(() => {
    if (!conclusionSection || !conclusionSection.items) return;
    conclusionSection.items.forEach(function (entry) {
      set_content(entry);
    });
  }, [conclusionSection]);

  useEffect(() => {
    set_parsedParagraphText(
      content?.fields.sectionParagraph
        ? createRichTextOptions(content.fields.sectionParagraph)
        : null
    );
  }, [content]);

  return (
    <section className="conclusion-section">
      <div className="container conclusion-container">
        <div className="text-column">
          <h2 className="hero-title hero-title-conclusion">
            {content?.fields.sectionTitle}
          </h2>
          <div className="intro-text intro-text-conclusion">
            {parsedParagraphText}
          </div>
        </div>
        <FlowerIcon className="flower-icon" />
      </div>
    </section>
  );
}
