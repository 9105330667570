import React, { useEffect, useState } from "react";
import "./FormComponent.scss";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import QuestionComponent from "./QuestionComponent";
import { useDatabase } from "../../context/DatabaseContext";
import { CheckMark, ArrowBackIcon, RandstadIcon } from "../Icons";

export default function FormComponent() {
  const { updateFormData, formData, returnBucketById } = useDatabase();
  let { bucketId } = useParams();
  const [currentQuestion, set_currentQuestion] = useState(0);
  const [questionArray, set_questionArray] = useState([]);
  const [formBodySingle, set_formBodySingle] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const mountForm = async () => {
      const bucket = await returnBucketById(bucketId);
      if (bucket) {
        set_formBodySingle(bucket);
      }
      if (formData) {
        const filteredFormData = formData.find(
          (item) => bucketId === item.id
        )?.data;
        if (filteredFormData) set_questionArray(filteredFormData);
      }
    };
    mountForm();
  }, [bucketId, formData, returnBucketById]);

  useEffect(() => {
    if (formData) {
      const totalQuestionsAnswered = formData.find(
        (item) => bucketId === item.id
      )?.data?.length;

      if (totalQuestionsAnswered)
        set_currentQuestion(totalQuestionsAnswered - 1);
    }
  }, []);

  const updateBucket = async (
    id,
    answer,
    maturityLevel,
    questionName,
    note
  ) => {
    const questionExists = questionArray.find((item) => item.questionId === id);
    let updateQuestionArray;
    if (questionExists) {
      updateQuestionArray = questionArray.map((item) => {
        if (id === item.questionId) {
          return { ...item, answer, maturityLevel, questionName, note };
        }
        return item;
      });
    } else {
      updateQuestionArray = [
        ...questionArray,
        {
          questionId: id,
          answer,
          maturityLevel,
          questionName,
          note,
          priorityState: false,
        },
      ];
    }
    await updateFormData(bucketId, updateQuestionArray, id);
  };

  const question = questionArray.find((q) => {
    return (
      q.questionId === formBodySingle?.fields.question[currentQuestion].sys.id
    );
  });

  const setCurrentQuestion = () => {
    if (formBodySingle.fields.question.length > currentQuestion + 1) {
      set_currentQuestion(currentQuestion + 1);
    }
  };

  const setSpecificQuestion = (index) => {
    set_currentQuestion(index);
  };

  if (!formBodySingle)
    return (
      <div className="loading-container">
        <h2 className="loading-message">Loading...</h2>
      </div>
    );

  return (
    <section className="form-section">
      <div className="container">
        <h2
          style={{ color: formBodySingle.fields.backgroundColor }}
          className="bucket-title"
        >
          {formBodySingle.fields.bucketTitle}
        </h2>
        <RandstadIcon className="randstad-icon-form" />
        <div className="form-component-container">
          <div className="navigation-panel">
            {formBodySingle.fields.question.map((item, index) => {
              const matchingQuestion =
                item.sys.id ===
                formBodySingle.fields.question[currentQuestion].sys.id;

              const hasAnswer = questionArray.find((answer) => {
                return answer.questionId === item.sys.id;
              });

              return (
                <div
                  className="navigation-content-item"
                  key={item.sys.id}
                  style={{
                    color:
                      matchingQuestion || hasAnswer ? "#101941" : "#838798",
                  }}
                  onClick={() => {
                    setSpecificQuestion(index);
                  }}
                >
                  {hasAnswer ? (
                    <CheckMark />
                  ) : (
                    <p className="index">{index + 1} </p>
                  )}
                  <p className="navigation-list-item">
                    {item.fields.questionTitle}
                  </p>
                </div>
              );
            })}
            <Link
              to="/dashboard"
              type="link"
              className="form-component-go-back"
            >
              <ArrowBackIcon />
              back to dashboard
            </Link>
          </div>
          <div className="question-panel">
            <QuestionComponent
              key={formBodySingle.fields.question[currentQuestion].sys.id}
              setAnswer={updateBucket}
              data={formBodySingle.fields.question[currentQuestion].fields}
              id={formBodySingle.fields.question[currentQuestion].sys.id}
              questionName={
                formBodySingle.fields.question[currentQuestion].fields
                  .questionTitle
              }
              questionActive={question?.answer}
              backgroundColor={formBodySingle.fields.backgroundColor}
              questionArray={questionArray}
            />
            {questionArray.length / formBodySingle.fields.question.length ===
            1 ? (
              <Link
                to="/dashboard"
                className="custom-button next-button"
                onClick={() => setCurrentQuestion()}
              >
                save answers
                <ArrowBackIcon className="button-down" color="white" />
              </Link>
            ) : (
              <button
                className="custom-button next-button"
                onClick={() => setCurrentQuestion()}
                disabled={!question?.answer}
              >
                next
                <ArrowBackIcon className="button-down" color="white" />
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
