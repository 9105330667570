import React, { useState, useEffect } from "react";
import { useDatabase } from "../../../context/DatabaseContext";
import "../SuccesPage.scss";
import { RandstadLogo } from "../../Icons";

export default function HeroSection() {
  const { formIntro } = useDatabase();
  const [content, set_content] = useState();

  useEffect(() => {
    if (!formIntro || !formIntro.items) return;
    formIntro.items.forEach(function (entry) {
      set_content(entry);
    });
  }, [formIntro]);

  return (
    <section className="intro-section">
      <div className="container hero-container">
        <RandstadLogo className="randstad-logo randstad-logo-succes" />
        <h1 className="hero-title">
          {content ? content.fields.heroTitle : ""}
        </h1>
        <h1 className="hero-subtitle">
          {content ? content.fields.heroSubtitle : ""}
        </h1>
      </div>
    </section>
  );
}
