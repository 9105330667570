// import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const createRichTextOptions = (document) => {
  return documentToReactComponents(document, {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const destination = node.data.target.sys.id;
        return destination ? (
          <a href={destination}>{node.content.map((i) => i.value)}</a>
        ) : (
          <strong>{node.content.map((i) => i.value)}</strong>
        );
      },
    },
  });
};

export default createRichTextOptions;
