import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import "./theme.scss";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Users from "./components/Users/Users";
import IntroductionBlock from "./components/IntroductionBlock/IntroductionBlock";
import FormComponent from "./components/FormComponents/FormComponent";
import Help from "./components/Help/Help";
import Priorities from "./components/Priorities/Priorities";
import { useAuth } from "./context/AuthContext";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Navigation from "./components/Navigation/Navigation";
import { QuestionMark } from "./components/Icons";
import { BrowserRouter as Router } from "react-router-dom";
import SuccesPage from "./components/SuccesPage/SuccesPage";
import SelectCountryScreen from "./components/SelectCountryScreen/SelectCountryScreen";

const ProtectedRoute = ({ component, ...args }) => {
  const { currentUser } = useAuth();
  const [helpActive, set_helpActive] = useState(false);
  
  if (currentUser && currentUser.unauthorized)
    return (
      <div className="loading-container">
        <h2 className="loading-message">
          Ask your system administrator to add you to one country
        </h2>
        <Navigation />
      </div>
    );
  if (helpActive)
    return <Help helpActive={helpActive} setHelpActive={set_helpActive} />;

  if (currentUser && !currentUser.country && currentUser.countryList.length > 1) {
    return <SelectCountryScreen />;
  }
  return (
    <>
      <div
        onClick={() => {
          set_helpActive(!helpActive);
        }}
        className="help-button"
      >
        <p>help</p>
        <QuestionMark />
      </div>
      <Route component={withAuthenticationRequired(component)} {...args} />
      <Navigation />
    </>
  );
};

export default function App() {
  return (
    <div className="app-container">
      <div className="content-container">
        <Router>
          <Switch>
            <ProtectedRoute exact path="/intro" component={IntroductionBlock} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <ProtectedRoute exact path="/users" component={Users} />
            <ProtectedRoute
              exact
              path="/countries"
              component={SelectCountryScreen}
            />
            <ProtectedRoute
              exact
              path="/dashboard/:bucketId"
              component={FormComponent}
            />
            <ProtectedRoute exact path="/priorities" component={Priorities} />
            <ProtectedRoute exact path="/result" component={SuccesPage} />
            <Route path="/" component={Login} />
          </Switch>
        </Router>
      </div>
    </div>
  );
}
