import React from "react";
import "./Login.scss";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { BlueCircle, RandstadLogo, RedCircle } from "../Icons";

export default function Login() {
  const { isAuthenticated, loginWithPopup } = useAuth0();

  if (isAuthenticated) {
    return <Redirect to="/intro" />;
  }
  return (
    <section className="login-section">
      <div className="container container-login">
        <BlueCircle className="blue-circle background-decoration" />
        <RedCircle className="red-circle background-decoration" />
        <RandstadLogo className="randstad-logo randstad-logo-login" />
        <div className="login-window">
          <h3 className="login-title">Sign in</h3>
          <p className="login-intro-text">
            to continue to <span>Randstad</span>
          </p>
          <div className="w-100 text-center mt-3">
            <button
              className="custom-button-login"
              onClick={(e) => loginWithPopup()}
            >
              Log in
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
