import client from "../../contentful/client";
import { database } from "../../firebase";

// export function setFormBody(data) {
//   set_formBody(data);
// }

/**
 * get data previously submitted (before named readUserData)
 * @param {string} userCountry
 * @returns the user country collection
 */

export async function userResponseData(userCountry, bucketContainer) {
  try {
    const res = await database.ref("formSubmit").get();
    // if we find it we are done
    if (res.child(userCountry).val()) {
      return res.child(userCountry).val();
    }
    //if not, create a default one!
    const data = bucketContainer.fields.bucket.map((item) => {
      return {
        id: item.sys.id,
        bucketName: item.fields.bucketTitle,
        data: null,
      };
    });
    // save it
    await database.ref("formSubmit/" + userCountry).set(data);
    // return it!
    return data;
  } catch (e) {
    console.log("ERROR", e);
    throw e;
  }
}

/**
 *
 * @param {string} currentUserCountry
 * @returns
 */
export async function getBucketContainer(currentUserCountry) {
  try {
    const allBucketContainers = await client.getEntries({
      content_type: "bucketContainer",
      include: 5,
    });

    const bucketContainer = allBucketContainers.items.find(
      (entry) => currentUserCountry === entry.fields.country
    );

    return bucketContainer;
  } catch (e) {
    console.log(e);
  }
}

export async function getFormIntro() {
  try {
    const formIntro = await client.getEntries({
      content_type: "introductionBlock",
      include: 2,
    });
    return formIntro;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function getFormPriorities() {
  try {
    const formPriorities = await client.getEntries({
      content_type: "prioritiesPage",
      include: 2,
    });
    return formPriorities;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function submitFormObject(userCountry) {
  const currentFormData = await database.ref("formSubmit/" + userCountry).get();

  const bucketData = await getBucketContainer(userCountry);
  const now = new Date();

  const currentForm = currentFormData.val();
  await database.ref("submittedForm/" + userCountry).set({
    data: currentForm,
    submissionUTC: now.toUTCString(),
    form: bucketData,
  });
}

// export function initializeFormData(formBucket, userCountry) {
//   console.log("INITIALIZING FORM DATA", formBucket);

//   const data = formBucket.fields.bucket.map((item) => {
//     const questions = item.fields.question.map((subItem) => {
//       return {
//         answer: "",
//         maturityLevel: "",
//         note: "",
//         questionId: subItem.sys.id,
//         questionName: subItem.fields.questionTitle,
//       };
//     });
//     return { data: questions, id: item.sys.id };
//   });

//   // send to database
//   try {
//     database.ref("formSubmit/" + userCountry).set(data);
//   } catch (e) {
//     console.log(e);
//   }
// }

export async function pushFormDataToDB(formData, userCountry) {
  try {
    //send to database
    await database.ref("formSubmit/" + userCountry).set(formData);
    return true;
  } catch (e) {
    console.log(e);
  }
}

export async function getFormDataFromDB(userCountry) {
  try {
    //get to database
    const formData = await database.ref("formSubmit/" + userCountry).get();
    return formData;
  } catch (e) {
    console.log(e);
  }
}

//Contentful functions
//Get introPage

//Get faqSection
export async function getFaqSection() {
  return client
    .getEntries({ content_type: "faqBlock", include: 3 })
    .catch((er) => {
      console.log("er", er);
    });
}

//Get contactSection
export async function getContactSection() {
  return client
    .getEntries({ content_type: "contactBlock", include: 3 })
    .catch((er) => {
      console.log("er", er);
    });
}

//Get resultSection
export async function getResultSection() {
  return client
    .getEntries({ content_type: "resultBlock", include: 3 })
    .catch((er) => {
      console.log("er", er);
    });
}

//Get breakdownSection
export async function getScoreBreakdownSection() {
  return client
    .getEntries({ content_type: "scoreBreakdownBlock", include: 3 })
    .catch((er) => {
      console.log("er", er);
    });
}

//Get conclusionSection
export async function getConclusionSection() {
  return client
    .getEntries({ content_type: "conclusionBlock", include: 3 })
    .catch((er) => {
      console.log("er", er);
    });
}

// //Get getBucket
export async function returnBucketById(bucketId) {
  try {
    const bucket = await client.getEntry(bucketId, { include: 3 });
    return bucket;
  } catch (er) {
    console.log("er", er);
  }
}

// //setPriorityState
// export async function setPriorityState(
//   maturityLevel,
//   priorityItem,
//   userCountry
// ) {
//   const newArray = priorityState.map((item) => {
//     if (item.maturityLevel === maturityLevel) {
//       if (item.data.includes(priorityItem)) {
//         const index = item.data.indexOf(priorityItem);
//         if (index > -1) {
//           item.data.splice(index, 1);
//           return item;
//         }
//         return item;
//       } else if (!item.data.includes(priorityItem) && item.data.length < 3) {
//         item.data = [...item.data, priorityItem];
//         return item;
//       } else {
//         return item;
//       }
//     } else {
//       return item;
//     }
//   });

//   set_priorityState(newArray);

//   //send to database
//   try {
//     database.ref("priorityState/" + userCountry).set(newArray);
//   } catch (e) {
//     console.log(e);
//   }
// }

// //initialize priorityState
// export async function initializePriorityState(userCountry) {
//   try {
//     const res = await database.ref("priorityState").get();
//     if (res) {
//       const response = res.child(userCountry).val();
//       if (response) {
//         const updateResponse = response.map((item) => {
//           if (!item.hasOwnProperty("data")) {
//             return { ...item, data: [] };
//           } else {
//             return item;
//           }
//         });
//         set_priorityState(updateResponse);
//       }
//       return;
//     } else {
//       console.log("no data available");
//     }
//   } catch (e) {
//     console.log(e);
//   }
// }
